import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=53ed464a&scoped=true&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed464a",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BFormInput, BFormGroup, BIconBoxArrowInRight, BButton, BLink, BForm, BCardBody} from 'bootstrap-vue'
    installComponents(component, {BAlert, BFormInput, BFormGroup, BIconBoxArrowInRight, BButton, BLink, BForm, BCardBody})
    

export default component.exports