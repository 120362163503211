<template>
  <div>
    <div class="p-3 fixed">
      <b-alert variant="success" :show="success != null" class="m-0 auto">{{ success }}</b-alert>
    </div>
    <b-card-body>
      <b-form @submit="onSubmit" v-show="true">
        <b-form-group label="PASSWORD:" label-for="password" label-cols="false" label-cols-md="6" label-cols-lg="4" label-align="false" label-align-md="right">
          <b-form-input id="password" ref="passwordInput" v-model="password" type="password" required/>
        </b-form-group>
        <b-form-group label="CONFIRM PASSWORD:" label-for="password" label-cols="false" label-cols-md="6" label-cols-lg="4" label-align="false" label-align-md="right">
          <b-form-input id="password2" ref="passInput" v-model="password2" type="password" :state="passwordsMatch" required></b-form-input>
          <b-form-invalid-feedback>Passwords must be identical.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group align="end">
          <b-button type="submit" variant="info"><b-icon-box-arrow-in-right></b-icon-box-arrow-in-right> {{ isActivateAccount ? 'Complete Registration' : 'Set New Password' }}</b-button>
        </b-form-group>
      </b-form>
    </b-card-body>
  </div>
</template>

<script>
  export default {
    name: 'SetPassword',
    data () {
      return {
        password: '',
        password2: '',
        success: null,
        redirectTimeout: null
      }
    },
    mounted () {
      document.title = `${this.isActivateAccount ? 'Activate Account' : this.isVerifyEmail ? 'Verify Email' : 'Reset Password'} | PBFC`
      // if already logged in, logout?
      // if (this.$store.getters.isLoggedIn) {
      //   this.$router.replace('Reports')
      // }
    },
    beforeDestroy () {
      clearTimeout(this.redirectTimeout)
    },
    computed: {
      isActivateAccount () {
        return this.$route.name == 'ActivateAccount'
      },
      isVerifyEmail () {
        return this.$route.name == 'VerifyEmail'
      },
      isResetPassword () {
        return this.$route.name == 'ResetPassword'
      },
      passwordsMatch () {
        if (this.password2 == '')
          return null
        return (this.password === this.password2)
      }
    },
    methods: {
      onSubmit (evt) {
        evt.preventDefault()
        if (!this.passwordsMatch) {
          return
        }
        this.$http.post('/activate/', {
          password: this.password,
          uid: this.$route.query.uid,
          token: this.$route.query.token
        }, {
            headers: {
              'Content-type': 'application/json'
            }
          })
          .then(response => {
            console.log(response)
            this.success = 'Password saved. You will be redirected to the login page in 5 seconds.'
            this.redirectTimeout = setTimeout(() => {
              this.$router.replace({ name: 'Login' })
            }, 5000)
          })
          .catch(e => {
            // this.form = {}
            console.log('Activate account', e, e.response)
            this.setAlert({ variant: 'danger', message: e.response.data })
          })
          // .then(() => {
          //   this.$router.push('Reports')
          // })
      }
    }
  }
</script>

<style scoped>

</style>
