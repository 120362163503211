import { render, staticRenderFns } from "./SetPassword.vue?vue&type=template&id=2143648e&scoped=true&"
import script from "./SetPassword.vue?vue&type=script&lang=js&"
export * from "./SetPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2143648e",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BFormInput, BFormGroup, BFormInvalidFeedback, BIconBoxArrowInRight, BButton, BForm, BCardBody} from 'bootstrap-vue'
    installComponents(component, {BAlert, BFormInput, BFormGroup, BFormInvalidFeedback, BIconBoxArrowInRight, BButton, BForm, BCardBody})
    

export default component.exports