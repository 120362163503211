<template>
  <div>
    <div class="p-3 fixed">
      <b-alert variant="danger" :show="error != null" dismissible @dismissed="dismissError" class="m-0 auto">{{ error }}</b-alert>
    </div>
    <b-card-body>
      <b-form @submit.prevent="onSubmit" v-show="true">
        <b-form-group label="EMAIL:" label-for="email" label-cols="false" label-cols-lg="4" label-align="false" label-align-lg="right">
          <b-form-input id="email" ref="emailInput" v-model="form.email" type="email" :state="emailInputState" required autofocus/>
        </b-form-group>
        <b-form-group label="PASSWORD:" label-for="password" label-cols="false" label-cols-lg="4" label-align="false" label-align-lg="right">
          <b-form-input id="password" ref="passInput" v-model="form.password" type="password" :state="passwordInputState" required></b-form-input>
        </b-form-group>
        <b-form-group align="end">
          <b-button type="submit" variant="info"><b-icon-box-arrow-in-right></b-icon-box-arrow-in-right> Login</b-button>
        </b-form-group>
        <div class="text-center">
          <b-link class="text-white-50 mr-2" @click="resetPassword">Reset Password</b-link>
        </div>
      </b-form>
    </b-card-body>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  export default {
    name: 'Login',
    data () {
      return {
        form: {
          email: '',
          password: '',
        },
        error: null,
        emailInputState: null,
        passwordInputState: null
      }
    },
    mounted () {
      document.title = 'Login | PBFC'
      // if already logged in, redirect to reports
      if (this.$store.getters.isLoggedIn) {
        this.$router.replace('Map')
      }
    },
    methods: {
      ...mapMutations([
        'setJwt', 'setUser', 'setCustomReports'
      ]),
      onSubmit () {
        this.$http.post('/token/', this.form, {
            headers: {
              'Content-type': 'application/json'
            }
          })
          .then(response => {
            console.log('Login onSubmit', response)
            this.setJwt({ refresh: response.data.refresh, access: response.data.access })
            this.setUser( response.data.user )
            this.setCustomReports(response.data.report_list)
            if (this.$store.getters.isScanExport)
            {
              this.$router.push(this.$route.query.redirect || { name: 'ScanTable' })
            } else
            {
              this.$router.push(this.$route.query.redirect || { name: 'Map' })
            }
          })
          .catch(e => {
            // this.form = {}
            console.log('Login onSubmit', e, e.message)
            if (e.status == 401) {
              this.emailInputState = false
              this.passwordInputState = false
              this.error = 'Invalid username and/or password.'
            } else {
              this.error = 'Error connecting to server. Please try again later.'
            }
          })
      },
      resetPassword () {
        this.dismissError()
        if (this.form.email == '') {
          this.emailInputState = false
          this.error = 'Please enter your email address and click "Reset Password" again.'
        } else {
          // ask backend to send email
          this.$http.post('/reset/', { email: this.form.email })
            .then(response => {
              console.log('Login resetPassword', response)
              this.setAlert({ 'variant': 'success', 'message': 'An email has been sent to you. Please click the link in the email to reset your password.' })
            })
            .catch(e => {
              console.log('Login resetPassword', e, e.message)
            })
        }
      },
      dismissError () {
        this.error = null
        this.emailInputState = null
        this.passwordInputState = null
      },
    }
  }
</script>

<style scoped>

</style>
