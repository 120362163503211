<template>
  <b-container fluid class="p-0">
    <b-row cols-sm="2" class="h-100 w-100 p-0 m-0">
      <b-col sm="6" lg="7" class="logo-panel">
        <b-row class="h-100" align-v="center">
          <b-col>
            <b-link :to="{ name: 'Login' }"><b-img :src="require('assets/logo.png')" fluid alt="Pincher Creek Feeders Cooperative"></b-img></b-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6" lg="5" class="bg-primary form-panel">
        <b-row class="h-100" align-v="center">
          <b-col>
            <b-card class="bg-secondary text-light shadow">
              <router-view/>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Home',
    data () {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style scoped>
</style>
